import { $get } from '@interactivevision/visitor';
import React, { FunctionComponent, useState } from 'react';
import { Modal } from './modal';
import styles from './modal-search.module.scss';
import { Form, Field } from 'react-final-form';
import { useDebounce } from '@interactivevision/media/lib/Hooks/useDebounce';
import { FormInput } from '../form/form-input';
import { route } from '@interactivevision/routing';
import type { Product } from '@interactivevision/products/lib/Api/Models/Product';
import { getSearchHints } from '../api/search/get-search-hints';
import ModalContent from './partials/modal-content';
import ModalHeader from './partials/modal-header';
import { ProductSearch } from '../components/product/product-search';


export type ModalSearchProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<boolean>
  stickyTop?: boolean;
};


export const ModalSearch: FunctionComponent<ModalSearchProps> = ({ isOpen, setIsOpen, stickyTop }) => {
  const [products, setProducts] = useState<Product[]>();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setLoading(false);
  };

  const handleSearch = useDebounce((value) => {
    if (value.length >= 3) {
      getSearchHints(value).then((products) => setProducts(products));
    }
  }, 300);

  const onSubmit = async (values: { search: string }) => {
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.append('search', values.search);
    const queryString = queryParams.toString();

    if (queryString) {
      const url = route('product_module') + '?' + queryString;
      $get(url).then(() => handleClose());
    } else {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} stickyTop={stickyTop}>
      <ModalContent className={styles.container}>
        <ModalHeader title="Szukaj" onClose={handleClose}/>
        <div>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate className={styles.form}>
                <Field name="search" parse={value => {
                  handleSearch(value);
                  return value;
                }
                }>
                  {({ input, meta }) => (
                    <FormInput disabled={loading} placeholder="Wprowadź nazwę produktu..." required light {...input} error={meta.submitError}/>
                  )}
                </Field>
              </form>
            )}
          </Form>
          {products && products.length > 0 && (
            <div className={styles.products}>
              {products.map((product, index) => (
                <ProductSearch product={product} key={index}/>
              ))
              }
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};
