import classNames                                                         from "classnames";
import React, {DetailedHTMLProps, FunctionComponent, InputHTMLAttributes} from "react";
import styles                                                             from "./input.module.scss";


export type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Input: FunctionComponent<InputProps> = ({id, name, ...props}) => (
  <input
    id={id || name}
    name={name}
    {...props}
    className={classNames(styles.input, props.className)}
  />
);
