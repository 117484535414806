import React, {FunctionComponent} from "react";
import styles                     from "./checkbox.module.scss";
import classNames                 from "classnames";
import {Input}                    from "./input";
import {InputProps}               from "./input";


export const Checkbox: FunctionComponent<InputProps> = ({children, required, ...props}) => (
  <label className={classNames(styles.checkbox, {[styles.disabled]: props.disabled})}>
    <Input type="checkbox" {...props} required={required}/>
    <span className={styles.check}/>
    {children && (
      <div className={classNames(styles.label, { [styles.required]: required })}>
        {children}
      </div>
    )}
  </label>
);
