import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import Box from './box';
import styles from './user.module.scss';


const Cart: FunctionComponent<{ className?: string }> = ({ className }) => {
  return (
    <div className={classNames(className, styles.user)}>
      <button type="button">
        <Box>
          <UserIcon/>
          <span>Twoje konto</span>
        </Box>
      </button>
    </div>
  );
};

export default Cart;
