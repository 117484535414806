import { route } from '@interactivevision/routing';
import { useSession, Link } from '@interactivevision/visitor-react';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { ReactComponent as HeartIcon } from '../../../assets/icons/heart.svg';
import Box from './box';
import styles from './favourites.module.scss';


const Favourites: FunctionComponent<{ className?: string }> = ({ className }) => {
  const session = useSession();

  return (
    <div className={classNames(className, styles.favourites)}>
      {/*<Link href={session.is_authenticated ? route('customer.favorites.index') : route('login')} title="Ulubione">*/}
      <Link href={route('homepage_module')} title="Ulubione">
        <Box>
          <HeartIcon/>
          <span>ulubione</span>
        </Box>
      </Link>
    </div>
  );
};

export default Favourites;
