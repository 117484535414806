import React                              from "react";
import styles                             from "./upper.module.scss";
import {useShared}                        from "@interactivevision/visitor-react";
import {ReactComponent as PhoneIcon}      from "../../assets/icons/phone.svg";
import {ReactComponent as PaperPlaneIcon} from "../../assets/icons/paper-plane.svg";
import {ReactComponent as FBIcon}         from "../../assets/icons/fb.svg";
import {ReactComponent as IGIcon}         from "../../assets/icons/ig.svg";
import {ReactComponent as TwitterIcon}    from "../../assets/icons/twitter.svg";
import {ReactComponent as PinterestIcon}  from "../../assets/icons/pinterest.svg";


const Upper = () => {
  // @ts-ignore
  const {contact} = useShared();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.contact}>
          <a href={`tel:${contact.phone_number_sale}`} title="Telefon kontaktowy - SPRZEDAŻ" className={styles.showOnMobile}>
            <PhoneIcon/>
            <span>{contact.phone_number_sale} - SPRZEDAŻ</span>
          </a>
          <a href={`tel:${contact.phone_number_office}`} title="Telefon kontaktowy - SEKRETARIAT">
            <PhoneIcon/>
            <span>{contact.phone_number_office} - SEKRETARIAT</span>
          </a>
          <a href={`mailto:${contact.email}`} title="Kontaktowy adres email">
            <PaperPlaneIcon/>
            <span>{contact.email}</span>
          </a>
        </div>
        <div className={styles.socials}>
          <div className={styles.item}>

            <a href={contact.facebook_url} target="_blank" rel="noopener" title="FACEBOOK Bispol Sklep">
              <FBIcon/>
            </a>
            <a href={contact.twitter_url} target="_blank" rel="noopener" title="TWITTER Bispol Sklep">
              <TwitterIcon/>
            </a>
            <a href={contact.instagram_url} target="_blank" rel="noopener" title="INSTAGRAM Bispol Sklep">
              <IGIcon/>
            </a>
            <a href={contact.pinterest_url} target="_blank" rel="noopener" title="PINTEREST Bispol Sklep">
              <PinterestIcon/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upper;
