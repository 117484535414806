import React, { FunctionComponent } from 'react';
import Hamburger from './hamburger';
import styles from './navbar-mobile.module.scss';
import SectionWrapper from '../../../layout/parialts/section-wrapper';
import Favourites from './favourites';
import Cart from './cart';
import User from './user';
import Search from './search';

type NavbarMobileProps = {}

const NavbarMobile: FunctionComponent<NavbarMobileProps> = ({}) => {
  return (
    <div className={styles.container}>
      <SectionWrapper className={styles.wrapper}>
        <div className={styles.item}>
          <Hamburger/>
        </div>
        <div className={styles.item}>
          <Favourites/>
        </div>
        <div className={styles.item}>
          <Cart/>
        </div>
        <div className={styles.item}>
          <User/>
        </div>
        <div className={styles.item}>
          <Search/>
        </div>
      </SectionWrapper>
    </div>
  );
};

export default NavbarMobile;
