import { route } from '@interactivevision/routing';
import { useLocation, useSession, Link, useShared } from '@interactivevision/visitor-react';
import classNames from 'classnames';
import { useAtom } from 'jotai';
import React, { useEffect, useState, useRef } from 'react';
import { atomMenuMobile } from '../../atoms/atom-menu-mobile';
import styles from './menu-mobile.module.scss';
import Logo from './partials/logo';
import Menu from './partials/menu';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';
import { ReactComponent as EnvelopeIcon } from '../../assets/icons/envelope.svg';


const MenuMobile = () => {
  const session = useSession();
  // @ts-ignore
  const { contact } = useShared();
  const ref = useRef<any>(null);
  const [menuMobile, setMenuMobile] = useAtom(atomMenuMobile);
  const location = useLocation();
  const [openedLocation, setOpenedLocation] = useState<string>(location);

  useEffect(() => {
    setOpenedLocation(location);
  }, [menuMobile]);

  useEffect(() => {
    if (openedLocation !== location) {
      setMenuMobile(false);
    }
  }, [location]);

  return (
    <div className={classNames(styles.container, { [styles.show]: menuMobile })}>

      <div className={styles.wrapper} ref={ref}>
        <div className={styles.content}>
          <div className={styles.header}>
            <Logo className={styles.logo}/>
            <button type="button" onClick={() => setMenuMobile(false)} className={styles.close}>
              <CloseIcon/>
            </button>
          </div>
          <Menu className={styles.menu}/>
        </div>
        {
          contact && (
            <div className={styles.contact}>
              <a href={`mailto:${contact.email}`} title="Kontaktowy adres email" className={styles.item}>
                <EnvelopeIcon/>
                <span>{contact.email}</span>
              </a>
              <a href={`tel:${contact.phone_number_office}`} title="Telefon kontaktowy" className={styles.item}>
                <PhoneIcon/>
                <span>{contact.phone_number_office}</span>
              </a>
              <a href={`tel:${contact.phone_number_office}`} title="Telefon kontaktowy" className={styles.item}>
                <PhoneIcon/>
                <span>{contact.phone_number_office}</span>
              </a>
              {session.is_authenticated ? (
                <Link href={route('customer.show')} title="Moje konto" className={classNames(styles.item, styles.icon)}>
                  <UserIcon/>
                  <span>Moje konto</span>
                </Link>
              ) : (
                <Link href={route('login')} title="Zaloguj się" className={classNames(styles.item, styles.icon)}>
                  <UserIcon/>
                  <span>Zaloguj się</span>
                </Link>
              )}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default MenuMobile;
