import { Paragraph } from '../typography/paragraph';
import { Modal } from './modal';
import { FunctionComponent } from 'react';
import ModalContent from './partials/modal-content';
import ModalHeader from './partials/modal-header';

export const ModalNewsletter: FunctionComponent<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalContent>
        <ModalHeader onClose={onClose} title='Informacja' />
        <Paragraph>
          Wyrażam zgodę na otrzymywanie drogą elektroniczną na wskazany przeze mnie adres email informacji handlowej w rozumieniu art. 10 ust. 1 ustawy z dnia 18 lipca 2002 roku o świadczeniu usług
          drogą elektroniczną od BISPOL Sp. z o.o.
        </Paragraph>
      </ModalContent>
    </Modal>
  );
};
