import {$get}                        from "@interactivevision/visitor";
import classNames                    from "classnames";
import {useAtomValue}                from "jotai";
import {useSetAtom}                  from "jotai";
import React                         from "react";
import {atomCartWidget}              from "../../atoms/atom-cart-widget";
import {Base}                        from "../../typography/base";
import {Title}                       from "../../typography/title";
import CartWidgetCard                from "./cart-widget-card";
import styles                        from "./cart-widget.module.scss";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import {cartAtom}                    from "@interactivevision/orders/lib/atoms/cart-atom";
import {route}                       from "@interactivevision/routing";
import {Button}                      from "../button";
import {ReactComponent as CartIcon}  from "../../assets/icons/cart.svg";


const CartWidget = () => {
  const setCartWidget = useSetAtom(atomCartWidget);
  const cart          = useAtomValue<any>(cartAtom);

  const handleClose = () => {
    if (document) {
      document.body.classList.remove("scroll-lock");
    }
    setCartWidget(false);
  };

  const handleNavigate = () => {
    $get(route("cart.show")).then(() => handleClose());
  };

  if (!cart) {
    return null;
  }

  return (
    <div className={classNames(styles.container)}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Title as="h4" size={9} className={styles.title}>Koszyk ({cart.items?.length})</Title>
          <button type="button" onClick={handleClose} className={styles.close}>
            <CloseIcon/>
          </button>
        </div>
        <div className={styles.content}>
          {
            cart.items?.length > 0 ? (cart.items.map((item) => (
              <div className={styles.item} key={item.id}>
                <CartWidgetCard item={item} handleClose={handleClose}/>
              </div>
            ))) : (
              <div className={styles.empty}>
                <CartIcon/>
                <span>Twój koszyk jest pusty</span>
              </div>
            )
          }
        </div>
        <div className={styles.footer}>
          <div className={styles.total}>
            <Base as="span" size={9} weight={300}>Wartość zamówienia</Base>
            <Base as="span" size={9} weight={500}>{cart.total_gross_formatted}</Base>
          </div>
          <Button onClick={handleNavigate} disabled={cart.items.length === 0}>Przejdź to koszyka</Button>
        </div>
      </div>
    </div>
  );
};

export default CartWidget;
