import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import styles from './modal-header.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';


type ModalHeaderProps = {
  title?: string;
  onClose: () => void;
  className?: string;
}

const ModalHeader: FunctionComponent<ModalHeaderProps> = ({ title, onClose, className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      {
        title && (
          <div className={classNames(styles.title, 'is-uppercase')}>
            {title}
          </div>
        )
      }
      <button type="button" className={styles.button} onClick={onClose}>
        <CloseIcon/>
      </button>
    </div>
  );
};

export default ModalHeader;
