import React, {FunctionComponent, ElementType} from "react";
import {Base, FontSize}                        from "../../typography/base";
import styles                                  from "./section-title.module.scss";
import classNames                              from "classnames";


interface Props {
  children: string;
  className?: any;
  small?: boolean;
  size?: FontSize;
  as?: (string & ElementType<any>) | undefined;
  uppercase?: boolean;
  column?: boolean,
  withMarginBottom?: boolean;
}

const SectionTitle: FunctionComponent<Props> = ({children, className, small, size, as, uppercase, column}) => {

  const classes = classNames(
    styles.title,
    className,
    {
      [styles.column]: column
    }
  );

  return (
    <Base as={as || "h2"} size={size ?? (small ? 8 : 3)} weight={600} className={classNames(classes, {"is-uppercase": uppercase})}>
      {children}
    </Base>
  );
};

export default SectionTitle;
