import {Link}                              from "@interactivevision/visitor-react";
import React, {useState}                   from "react";
import Logo                                from "../../components/navbar/partials/logo";
import Newsletter                          from "../../components/newsletter";
import styles                              from "./footer.module.scss";
import {useShared}                         from "@interactivevision/visitor-react";
import SectionWrapper                      from "./section-wrapper";
import {ReactComponent as PinIcon}         from "../../assets/icons/pin.svg";
import {ReactComponent as PhoneIcon}       from "../../assets/icons/phone.svg";
import {ReactComponent as PaperPlane}      from "../../assets/icons/paper-plane.svg";
import {ReactComponent as FBIcon}          from "../../assets/icons/fb.svg";
import {ReactComponent as IGIcon}          from "../../assets/icons/ig.svg";
import classNames                          from "classnames";
import {ReactComponent as LogoIv}          from "../../assets/icons/logo-iv.svg";
import {ReactComponent as ChevronDownIcon} from "../../assets/icons/chevron-down.svg";


export default function Footer() {
  // @ts-ignore
  const {menu, contact} = useShared();

  const renderMenuList = (element, index) => {
    const [show, setShow] = useState<boolean>(false);

    return (
      <div key={index} className={styles.element}>
        <div className={styles.title}>
          <span>{element.name}</span>
          <button type="button" className={classNames(styles.toggle, {[styles.show]: show})} onClick={() => setShow(!show)}>
            <ChevronDownIcon/>
          </button>
        </div>
        {
          element.children && (
            <ul className={classNames(styles.list, {[styles.show]: show})}>
              {
                element.children.length > 0 && element.children.map((children, index) => (
                  <li key={index}>
                    <Link target={children.target} rel={children.rel} href={children.url} title={children.name}>
                      {children.name}
                    </Link>
                  </li>
                ))
              }
            </ul>
          )
        }
      </div>
    );
  };

  return (
    <>
      <Newsletter/>
      <footer className={styles.footer}>
        <SectionWrapper className={styles.wrapper}>
          <div className={styles.column}>
            <Logo light className={styles.logo}/>
            <div className={styles.contact}>
              <div className={styles.line}>
                <PinIcon/>
                <div className={styles.content}>
                  <div className={styles.box}>
                    <div className={styles.big}>BISPOL Sp. z o.o.</div>
                    <div className={styles.small}>
                      {contact.street}
                    </div>
                    <div className={styles.small}>
                      {contact.post_code} {contact.city}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.line}>
                <PhoneIcon/>
                <div className={styles.content}>
                  <div className={styles.box}>
                    <a href={`tel:${contact.phone_number_office}`} className={styles.big} title="Numer telefonu sekretariat BISPOL">{contact.phone_number_office}</a>
                    <div className={styles.small}>sekretariat</div>
                  </div>
                  <div className={styles.box}>
                    <a href={`tel:${contact.phone_number_sale}`} className={styles.big} title="Numer telefonu sprzedaż detaliczna BISPOL">{contact.phone_number_sale}</a>
                    <div className={styles.small}>sprzedaż detal</div>
                  </div>
                  <div className={styles.box}>
                    <a href={`tel:${contact.phone_number_wholesale}`} className={styles.big} title="Numer telefonu sprzedaż hurtowa BISPOL">{contact.phone_number_sale}</a>
                    <div className={styles.small}>sprzedaż hurt</div>
                  </div>
                  <div className={styles.box}>
                    <div className={styles.box}>
                      <a href={`tel:${contact.fax}`} className={styles.big} title="Numer fax do BISPOL">{contact.fax}</a>
                      <div className={styles.small}>fax</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classNames(styles.line, styles.mail)}>
                <PaperPlane/>
                <div className={styles.content}>
                  <a href={`mailto:${contact.email}`} title="Kontaktowy adres email">{contact.email}</a>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.menu}>
              {
                menu && menu.menu_bottom.length > 0 && menu.menu_bottom.map((element, index) => renderMenuList(element, index))
              }
            </div>
            <div className={styles.socials}>
              <div className={styles.item}>
                <a href={contact.facebook_url} target="_blank" rel="noopener" title="FACEBOOK Bispol Sklep">
                  <FBIcon/>
                </a>
              </div>
              <div className={styles.item}>
                <a href={contact.instagram_url} target="_blank" rel="noopener" title="INSTAGRAM Bispol Sklep">
                  <IGIcon/>
                </a>
              </div>
            </div>
          </div>
        </SectionWrapper>
        <SectionWrapper className={styles.bottom}>
          <div className={styles.column}>
            <span> 2023 BISPOL Sp. z o.o.</span>
          </div>
          <div className={classNames(styles.column, styles.realisation)}>
            <span>PROJEKT I REALIZACJA</span>
            <a href="https://interactivevision.pl/" target="_blank" rel="noopener" title="Realizacja INTERACTIVEVISION">
              <LogoIv/>
            </a>
          </div>
        </SectionWrapper>
      </footer>
    </>
  );
};

