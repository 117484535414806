import { Link } from '@interactivevision/visitor-react';
import classNames from 'classnames';
import React from 'react';
import styles from './submenu.module.scss';


const Submenu = ({ items, className }) => {

  return (
    <ul className={classNames(styles.container, className)}>
      {items.map((element, index) => (
        <li key={index}>
          <Link target={element.target} rel={element.rel} href={element.url} title={element.name}>
            {element.name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default Submenu;
