import {route}                           from "@interactivevision/routing";
import {Link}                            from "@interactivevision/visitor-react";
import classNames                        from "classnames";
import {FunctionComponent}               from "react";
import React                             from "react";
import styles                            from "./logo.module.scss";
import {ReactComponent as LogoIcon}      from "../../../assets/icons/logo.svg";
import {ReactComponent as LogoLightIcon} from "../../../assets/icons/logo-light.svg";


const Logo: FunctionComponent<{ className?: string; light?: boolean }> = ({className, light}) => {
  return (
    <Link href={route("homepage_module")} title="Logo BISPOL SKLEP" className={classNames(styles.logo, className)}>
      {light ? <LogoLightIcon/> : <LogoIcon/>}
    </Link>
  );
};

export default Logo;
