import React  from "react";
import styles from "./box.module.scss";


const Box = ({children}) => {
  return (
    <span className={styles.box}>{children}</span>
  );
};

export default Box;
