import classNames                                                                                                           from "classnames";
import React, {FunctionComponent, PropsWithChildren, DetailedHTMLProps, ButtonHTMLAttributes, ReactNode, MouseEventHandler} from "react";
import styles                                                                                                               from "./button.module.scss";


export type BaseButtonProps = {
  /** Styling of the button */
  kind?: "primary" | "secondary" | "tertiary" | "info" | "success" | "warning" | "danger";
  /** Stretches the button to it's maximal width */
  fullWidth?: boolean;
  /** Disables the button */
  disabled?: boolean;
  /** Show a circular progress bar */
  loading?: boolean;
  /** Renders the button highlighted */
  tile?: boolean;
  /** Change size of the button */
  big?: boolean;
  /** Align the button to a given side */
  /** Component classes */
  className?: string;
};


export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & BaseButtonProps & {
  prompt?: ReactNode;
};

export const Button: FunctionComponent<PropsWithChildren<ButtonProps>> = ({
  type = "button",
  className,
  children,
  disabled = false,
  onClick,
  kind = "primary",
  loading = false,
  fullWidth = false,
  tile = false,
  big = false,

  ...props
}) => {
  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  const classes = compileButtonClasses({
    className,
    kind,
    fullWidth,
    loading,
    big,
  });

  return (
    <button className={classes} disabled={disabled} type={type} onClick={handleOnClick} {...props}>
      {children && (
        <span className={classNames(styles.content, {[styles.loading]: loading})}>
        {children}
      </span>
      )}
    </button>
  );
};

type ButtonClassCompilerProps = {
  className?: string;
  kind: "primary" | "info" | "success" | "warning" | "danger" | "secondary" | "tertiary";
  fullWidth: boolean;
  loading: boolean;
  big: boolean;
};

export function compileButtonClasses(props: ButtonClassCompilerProps) {

  return classNames(
    props.className,
    styles.button,
    styles[props.kind],
    {
      [styles.fullWidth]: props.fullWidth,
      [styles.loading]:   props.loading,
      [styles.big]:       props.big,
    },
  );
}
