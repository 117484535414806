import { useAtomValue } from 'jotai';
import React from 'react';
import { atomCartWidget } from '../../atoms/atom-cart-widget';
import CartWidget from '../../components/cart-widget/cart-widget';
import Lower from '../../components/navbar/lower';
import Upper from '../../components/navbar/upper';
import styles from './navbar.module.scss';
import MenuMobile from '../../components/navbar/menu-mobile';


export const Navbar = () => {
  const cartWidget = useAtomValue(atomCartWidget);

  return (
    <>
      <nav className={styles.navbar}>
        <Upper/>
        <Lower/>
        {cartWidget && <CartWidget/>}
      </nav>
      {window && window.innerWidth <= 1100 && <MenuMobile/>}
    </>
  );
};
