import classNames from 'classnames';
import { useAtom } from 'jotai';
import React, { FunctionComponent } from 'react';
import { atomMenuMobile } from '../../../atoms/atom-menu-mobile';
import styles from './hamburger.module.scss';


const Hamburger: FunctionComponent<{ className?: string }> = ({ className }) => {
  const [menuMobile, setMenuMobile] = useAtom(atomMenuMobile);

  return (
    <div className={styles.wrapper}>
      <button type="button" className={classNames(styles.hamburger, className, { [styles.active]: menuMobile })} onClick={() => setMenuMobile(!menuMobile)}>
        <span/>
        <span/>
        <span/>
      </button>
    </div>
  );
};

export default Hamburger;
