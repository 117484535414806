import { cartAtom } from '@interactivevision/orders/lib/atoms/cart-atom';
import classNames from 'classnames';
import { useAtom, useSetAtom } from 'jotai';
import React, { FunctionComponent } from 'react';
import { ReactComponent as CartICon } from '../../../assets/icons/cart.svg';
import { atomCartWidget } from '../../../atoms/atom-cart-widget';
import Box from './box';
import styles from './cart.module.scss';
import Quantity from './quantity';


const Cart: FunctionComponent<{ className?: string }> = ({ className }) => {
  const setCartWidget = useSetAtom(atomCartWidget);
  const [cart] = useAtom<any>(cartAtom);

  const openCartWidget = () => {
    if (cart && document) {
      document.body.classList.add('scroll-lock');
      setCartWidget(true);
    }
  };

  return (
    <div className={classNames(className, styles.cart)}>
      {/*<button type="button" onClick={openCartWidget}>*/}
      <button type="button">
        <Box>
          <CartICon/>
          <span>koszyk</span>
        </Box>
        {cart && cart.items.length > 0 && <Quantity>{cart.items.length}</Quantity>}
      </button>
    </div>
  );
};

export default Cart;
