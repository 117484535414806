import React, { useState } from 'react';
import SectionTitle from '../layout/parialts/section-title';
import SectionWrapper from '../layout/parialts/section-wrapper';
import { Form } from 'react-final-form';
import Section from '../layout/parialts/section';
import { Paragraph } from '../typography/paragraph';
import styles from './newsletter.module.scss';
import { FieldInput } from '../form/fields/field-input';
import { Button } from './button';
import { FieldCheckbox } from '../form/fields/field-checkbox';
import { ModalNewsletter } from '../modal/modal-newsletter';

const Newsletter = () => {
  const [modal, setModal] = useState<boolean>(false);

  const handleSubmit = (values: any) => {
    console.log(values);
  };

  return (
    <>
      <Section className={styles.container}>
        <SectionWrapper className={styles.wrapper}>
          <div className={styles.column}>
            <SectionTitle as='h3' size={3} uppercase>
              Newsletter
            </SectionTitle>
            <Paragraph className={styles.text}>Bogata oferta produktów, która w 100% spełnia oczekiwania naszych klientów</Paragraph>
          </div>
          <div className={styles.column}>
            <Form onSubmit={handleSubmit}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate className={styles.form}>
                  <div className={styles.flex}>
                    <div className={styles.wrap}>
                      <FieldInput name='email' placeholder='Twój adres email' className={styles.input} required />
                    </div>
                    <Button type='submit' className={styles.button}>
                      Subskrybuj
                    </Button>
                  </div>
                  <div className={styles.checkbox}>
                    <FieldCheckbox name='rodo'>
                      Wyrażam zgodę na otrzymywanie drogą elektroniczną na wskazany przeze mnie adres email informacji...
                      <button type='button' className={styles.modal} onClick={() => setModal(true)}>
                        pokaż całość
                      </button>
                    </FieldCheckbox>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </SectionWrapper>
      </Section>
      <ModalNewsletter onClose={() => setModal(false)} isOpen={modal} />
    </>
  );
};

export default Newsletter;
