import classNames from 'classnames';
import { Base, FontSize, Weight } from './base';
import { forwardRef, HTMLAttributes, PropsWithChildren } from 'react';

export type ParagraphProps = HTMLAttributes<HTMLParagraphElement> & {
  weight?: Weight;
  size?: FontSize;
};

export const Paragraph = forwardRef<any, PropsWithChildren<ParagraphProps>>((props, ref) => {
  return (
    <p {...props} ref={ref} className={props.className}>
      {props.children}
    </p>
  );
});
