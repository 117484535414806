import { useShared, Link } from '@interactivevision/visitor-react';
import classNames from 'classnames';
import React, { useState, FunctionComponent } from 'react';
import Submenu from '../../../layout/parialts/submenu';
import styles from './menu.module.scss';
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down.svg';


const Menu: FunctionComponent<{ className?: string; }> = ({ className }) => {
  const { menu }: any = useShared();

  const renderMenuItem = (element) => {
    const [submenu, showSubmenu] = useState(false);

    return (
      <li className={classNames({ [styles['has-submenu']]: element.children && element.children.length > 0 }, { [styles.active]: submenu })}>
        <Link target={element.target} rel={element.rel} href={element.url} title={element.name}>
          {element.name}
        </Link>
        {
          element.children && element.children.length > 0 && (
            <button type="button" className={styles.toggle} onClick={() => showSubmenu(!submenu)}>
              <ChevronDown/>
            </button>
          )
        }
        {
          element.children && element.children.length > 0 && (
            <Submenu items={element.children} className={styles.submenu}/>
          )
        }
      </li>
    );
  };

  return (
    <ul className={classNames(styles.menu, className)}>
      {
        menu?.menu_top.map((element, index) => (
          <React.Fragment key={index}>
            {renderMenuItem(element)}
          </React.Fragment>
        ))
      }
    </ul>
  );
};

export default Menu;
