import React from 'react';
import { Link, useShared } from '@interactivevision/visitor-react';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import classNames from 'classnames';
import styles from './lower.module.scss';
import { route } from '@interactivevision/routing';
import Cart from './partials/cart';
import Favourites from './partials/favourites';
import Search from './partials/search';
import User from './partials/user';
import Hamburger from './partials/hamburger';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phone.svg';


const Lower = () => {
  // @ts-ignore
  const { menu, contact } = useShared();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.flex}>
          <Hamburger className={styles.hamburger}/>
          <Link href={route('homepage_module')} title="Logo Bispol Sklep" className={styles.logo}>
            <Logo/>
          </Link>
          <ul className={classNames(styles.menu, styles.flex)}>
            {
              menu?.menu_top.map((element, index) => (
                <li key={index} className={styles.element}>
                  {/*<li key={index} className={classNames(styles.element, { [styles['has-submenu']]: element.children && element.children.length > 0 })}>*/}
                  <Link target={element.target} rel={element.rel} href={element.url} title={element.name}>{element.name}</Link>
                  {/*{*/}
                  {/*  element.children && element.children.length > 0 && (*/}
                  {/*    <Submenu items={element.children} className={styles.submenu}/>*/}
                  {/*  )*/}
                  {/*}*/}
                </li>
              ))
            }
          </ul>
        </div>
        <div className={classNames(styles.options, styles.flex)}>
          <Search className={styles.option}/>
          <Favourites className={styles.option}/>
          <Cart className={styles.option}/>
          <User className={styles.option}/>
        </div>
        <a href={`tel:${contact.phone_number_office}`} title="Telefon kontaktowy - SEKRETARIAT" className={styles.phone}>
          <PhoneIcon/>
          <span>{contact.phone_number_office}</span>
        </a>
      </div>
    </div>
  );
};

export default Lower;

